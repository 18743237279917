import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { QueryParamsUrlSerializer } from '@motivforce/mx-library-angular';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

function initializeApp() {
  const locales = [localeDe, localeEn, localeEs, localeFr, localeIt, localePt];
  return async () =>
    new Promise((resolve, reject) => {
      try {
        locales.forEach((locale) => registerLocaleData(locale));
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    AuthModule,
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true },
    { provide: UrlSerializer, useClass: QueryParamsUrlSerializer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
