<app-loading-indicator></app-loading-indicator>
<lib-admin-panel></lib-admin-panel>
<div class="d-flex flex-column h-100">
  <app-header *ngIf="!hideHeaderFooter"></app-header>

  <main
    role="main"
    class="flex-fill"
    [ngClass]="{
      'header-fixed': headerFixed && !hideHeaderFooter,
      'with-navbar': (authenticatedUser$ | async) !== null
    }"
  >
    <router-outlet></router-outlet>
  </main>

  <app-footer-top-nav
    *ngIf="!hideHeaderFooter"
    [loggedIn]="(authenticatedUser$ | async) !== null"
    [ngClass]="{
      'col-md-9': (authenticatedUser$ | async) === null && isLogin
    }"
  ></app-footer-top-nav>
  <app-footer
    class="mt-auto"
    *ngIf="!hideHeaderFooter"
    [ngClass]="{
      'col-md-9': (authenticatedUser$ | async) === null && isLogin
    }"
  ></app-footer>
</div>
