import { Component, OnInit } from '@angular/core';
import { AbstractDialogComponent } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as localStorage from 'local-storage';
import { UserStoreService } from 'src/app/core/store/user-store.service';

import { PromotionStoreService } from '../../../store/promotion-store.service';

@Component({
  selector: 'app-terms-conditions-accept-dialog',
  templateUrl: './terms-conditions-accept-dialog.component.html',
  styleUrls: ['./terms-conditions-accept-dialog.component.scss'],
})
export class TermsConditionsAcceptDialogComponent extends AbstractDialogComponent implements OnInit {
  tnCsChecked = false;
  tncTitle: string;
  tncBody: string;
  tncAccept: string;

  constructor(
    public activeModal: NgbActiveModal,
    private userStore: UserStoreService,
    private promotionStore: PromotionStoreService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs && this.inputs.length > 2) {
      this.tncTitle = this.inputs[0];
      this.tncBody = this.inputs[1];
      this.tncAccept = this.inputs[2];
    }
  }

  onSubmit(): void {
    const acceptedTerms = this.tnCsChecked;

    if (acceptedTerms) {
      this.activeModal.close('Save Click');

      this.userStore.acceptTermsConditions().then((success) => {
        if (success) {
          const urlKey = localStorage.get<string>('lenovoleap-url-key');
          const promotionId = localStorage.get<string>('lenovoleap-promotion-id');

          if (urlKey && promotionId) {
            this.promotionStore.awardEventReferral(urlKey, promotionId).then((awardSuccess) => {
              if (awardSuccess) {
                localStorage.remove('lenovoleap-url-key');
                localStorage.remove('lenovoleap-promotion-id');
              }
            });
          }
        }
      });
    }
  }
}
