import { Component, OnInit } from '@angular/core';
import { AuthStoreService, ContentGqlService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import { AbstractTncComponent } from 'src/app/core/components/abstract-tnc/abstract-tnc.component';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends AbstractTncComponent implements OnInit {
  year: string;

  constructor(protected contentService: ContentGqlService, private authStore: AuthStoreService) {
    super(contentService);
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();

    this.authStore.userSettings$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((userSettings) => {
      if (userSettings.user != null) {
        this.getTermsAndConditionUrl(userSettings.language);
      }
    });
  }
}
