<div class="wrapper" [ngStyle]="{ 'min-height': widgetsHeight ? widgetsHeight + 20 + 'px' : 'unset' }">
  <div class="h-100 d-flex flex-column">
    <div class="container d-none d-lg-block">
      <lib-banner
        [banner]="banner"
        [ngClass]="{ 'larger-banner': showLargerBanner, displayed: !!banner?.imageUrl }"
      ></lib-banner>
    </div>
    <lib-banner
      class="d-lg-none"
      [banner]="banner"
      [ngClass]="{ 'larger-banner': showLargerBanner, displayed: !!banner?.imageUrl }"
    ></lib-banner>

    <lib-breadcrumbs [delimiter]="'>'"></lib-breadcrumbs>

    <div class="container">
      <div class="mt-5 mt-lg-4 row">
        <div class="col-12">
          <div class="title" [innerHtml]="banner?.title"></div>
        </div>
      </div>
    </div>
    <div class="h-100 mt-4 mb-5 content-wrapper">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="pt-3 side-widgets" #sideWidgets *ngIf="showSideWidgets">
    <ng-content select="[sideWidgets]"></ng-content>
  </div>

  <app-back-to-top-button></app-back-to-top-button>
</div>
