import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { filter, map, Observable, take } from 'rxjs';

import { User } from '../model/user';

@Injectable({
  providedIn: 'root',
})
export class TermsAndConditionsAcceptedGuard implements CanActivate {
  constructor(private router: Router, private authStore: AuthStoreService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authStore.isRegularUser$.pipe(filter(Boolean), take(1)).pipe(
      map(() => {
        if (!(this.authStore.userSettings?.user as User)?.acceptedTermsConditions) {
          return this.navigateTo(state, '/home');
        }

        return true;
      })
    );
  }

  private navigateTo(state: RouterStateSnapshot, url: string): boolean | UrlTree {
    if (state.url.includes(url)) {
      return true;
    }
    return this.router.parseUrl(url);
  }
}
