<div class="container-fluid">
  <div class="row">
    <div class="px-0 col-12 col-lg-9 background-wrapper">
      <img class="w-100" [src]="background" />
      <h1>{{ 'Welcome to LEAP' | translate }}</h1>
    </div>
    <div class="col-12 col-lg-3 login-form-container">
      <div *ngIf="mode === 'admin'" class="w-100 h-100 d-flex align-items-center justify-content-center">
        <form 
          [formGroup]="formGroup"
          class="my-5 mt-lg-0 p-4 w-100 d-flex d-sm-none d-lg-flex flex-column"
          (ngSubmit)="onSubmit()"
        >
          <h5 class="text-uppercase">Internal Login</h5>
          <div class="pt-4 w-100 form-group">
            <label class="form-label">Username</label>
            <input id="email" type="text" formControlName="email" class="w-100 form-control" />
          </div>
          <div class="pt-4 w-100 form-group">
            <label class="form-label">Password</label>
            <input id="password" type="password" formControlName="password" class="w-100 form-control" />
          </div>
          <button class="mt-5 w-100 btn btn-primary" type="submit">Login</button>
          <button
            class="mt-4 w-100 btn btn-primary google"
            type="button"
            (click)="signInWithGoogle()"
          >
            Login with Google
          </button>
        </form>
        <form
          [formGroup]="formGroup"
          class="my-5 mt-lg-0 p-4 w-50 d-none d-sm-flex d-lg-none flex-column"
          (ngSubmit)="onSubmit()"
        >
          <h5 class="text-uppercase">Internal Login</h5>
          <div class="pt-4 w-100 form-group">
            <label class="form-label">Username</label>
            <input id="email" type="text" formControlName="email" class="w-100 form-control" />
          </div>
          <div class="pt-4 w-100 form-group">
            <label class="form-label">Password</label>
            <input id="password" type="password" formControlName="password" class="w-100 form-control" />
          </div>
          <button class="mt-5 w-100 btn btn-primary" type="submit">Login</button>
          <button
            class="mt-4 w-100 btn btn-primary google"
            type="button"
            (click)="signInWithGoogle()"
          >
            Login with Google
          </button>
        </form>
      </div>

      <div *ngIf="mode !== 'admin'" class="w-100 h-100 d-flex align-items-center justify-content-center">
        <div class="my-5 mt-lg-0 p-4 w-100 d-flex d-sm-none d-lg-flex flex-column">          
          <button class="mt-5 w-100 btn btn-primary" (click)="signIn()" >Enter</button>
        </div>


        <div class="my-5 mt-lg-0 p-4 w-50 d-none d-sm-flex d-lg-none flex-column">
          <button class="mt-5 w-100 btn btn-primary" (click)="signIn()" >Enter</button>
        </div>
      </div>
    </div>
  </div>
</div>
