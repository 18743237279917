import { Component, Input, OnInit } from '@angular/core';
import { Promotion } from 'src/app/core/model/promotion/promotion';
import { PromotionContent } from 'src/app/core/model/promotion/promotion-content';

@Component({
  selector: 'app-promotion-box',
  templateUrl: './promotion-box.component.html',
  styleUrls: ['./promotion-box.component.scss'],
})
export class PromotionBoxComponent implements OnInit {
  @Input() promotion: Promotion | null;
  @Input() hasThumbnailText = false;
  @Input() hasLargeTitle = false;

  promotionContent: PromotionContent;
  pcsdText = 'IDG';

  ngOnInit() {
    if (this.promotion) {
      this.promotionContent = this.promotion.promotionContents[0];
    }
  }
}
