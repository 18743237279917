import { Component, Input } from '@angular/core';

import { CustomListComponent } from '../custom-list/custom-list.component';

@Component({
  selector: 'app-custom-claimed-invoices-list',
  templateUrl: './custom-claimed-invoices-list.component.html',
  styleUrls: ['./custom-claimed-invoices-list.component.scss'],
})
export class CustomClaimedInvoicesListComponent extends CustomListComponent {
  @Input() selectable = true;

  override itemsPerPageDisplayKey = `${this.translate.instant('Items per page')}:`;
  override entriesDisplayKey = '';
}
