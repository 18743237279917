import { Injectable } from '@angular/core';
import { MxLoggerService } from '@motivforce/mx-library-angular';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';

import { CourseRestService } from '../api/rest/course-rest.service';
import { Course } from '../model/course/course';
import { CourseGroup } from '../model/course/course-group';

@Injectable({
  providedIn: 'root',
})
export class CourseStoreService {
  private readonly _currentCoursesResults = new BehaviorSubject<Course[] | null>(null);
  readonly currentCoursesResults$ = this._currentCoursesResults.asObservable();

  private readonly _currentCourseGroups = new BehaviorSubject<CourseGroup[] | null>(null);
  readonly currentCourseGroups$ = this._currentCourseGroups.asObservable();

  constructor(
    private courseRest: CourseRestService,
    private isLoadingService: IsLoadingService,
    private mxLogger: MxLoggerService
  ) {}

  get currentCoursesResults(): Course[] | null {
    return this._currentCoursesResults.getValue();
  }

  get currentCourseGroups(): CourseGroup[] | null {
    return this._currentCourseGroups.getValue();
  }

  searchCourses(courseSearch: any): void {
    this.isLoadingService.add(
      this.courseRest.searchCourses(courseSearch).subscribe((searchResult) => {
        this._currentCoursesResults.next(searchResult);
      })
    );
  }

  getCourseGroups(): void {
    if (!this.currentCourseGroups) {
      this.isLoadingService.add(
        this.courseRest.getCourseGroups().subscribe((groups) => {
          this._currentCourseGroups.next(groups);
        })
      );
    }
  }

  syncCourses(): void {
    this.isLoadingService.add(this.courseRest.syncCourses().subscribe());
  }
}
