import { Injectable } from '@angular/core';
import { ApiRestService, BasePage } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Course } from '../../model/course/course';
import { CourseGroup } from '../../model/course/course-group';

@Injectable({
  providedIn: 'root',
})
export class CourseRestService {
  constructor(protected apiRestService: ApiRestService) {}

  searchCourses(courseSearch: BasePage): Observable<Course[]> {
    return this.apiRestService.post<Course[]>(
      `${environment.api.core.baseUrl}/members/learncourses/search`,
      courseSearch
    );
  }

  getCourseGroups(): Observable<CourseGroup[]> {
    return this.apiRestService.get<CourseGroup[]>(`${environment.api.core.baseUrl}/members/learncourses/brand-options`);
  }

  syncCourses(): Observable<void> {
    return this.apiRestService.get<void>(`${environment.api.core.baseUrl}/members/learncourses/lpl/user/sync`);
  }
}
