import { Component, Input } from '@angular/core';
import { PromotionValenciaSummaryData } from 'src/app/core/model/promotion/promotion-valencia-summary-data';

@Component({
  selector: 'app-valencia-summary-widget',
  templateUrl: './valencia-summary-widget.component.html',
  styleUrls: ['./valencia-summary-widget.component.scss'],
})
export class ValenciaSummaryWidgetComponent {
  private _valenciaSummaryData: PromotionValenciaSummaryData;

  @Input()
  get valenciaSummaryData(): PromotionValenciaSummaryData {
    return this._valenciaSummaryData;
  }

  set valenciaSummaryData(value: PromotionValenciaSummaryData) {
    this._valenciaSummaryData = value;
  }
}
