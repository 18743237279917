<div class="d-flex flex-column justify-content-center wrapper" *ngIf="intelPremiumRewardsSidebarData">
  <div class="offcanvas-body d-flex flex-column">
    <h5 class="text-uppercase text-center title">{{ 'Premium rewards credits' | translate }}</h5>
    <div class="my-3 text-center subtitle">
      <strong>{{ 'Click on each category to track your credits performance' | translate }}</strong>
    </div>

    <div class="mt-5 d-flex justify-content-around justify-content-lg-between align-items-end">
      <div class="cup-button isg" [ngClass]="{ active: mode === 'Isg' || mode === null }" (click)="mode = 'Isg'">
        <div class="d-flex flex-column align-items-center">
          <h4>ISG</h4>
          <small class="text-uppercase">{{ 'Ranked' | translate }}</small>
          <small> {{ intelPremiumRewardsSidebarData.currentRankIsg }}</small>
        </div>
      </div>
      <div class="cup-button one-lenovo" [ngClass]="{ active: mode === '' || mode === null }" (click)="mode = ''">
        <div class="d-flex flex-column align-items-center">
          <h4 class="text-center">One<br />Lenovo</h4>
          <small class="text-uppercase">{{ 'Ranked' | translate }}</small>
          <small> {{ intelPremiumRewardsSidebarData.currentRankIsg }}</small>
        </div>
      </div>
      <div class="cup-button idg" [ngClass]="{ active: mode === 'Idg' || mode === null }" (click)="mode = 'Idg'">
        <div class="d-flex flex-column align-items-center">
          <h4>IDG</h4>
          <small class="text-uppercase">{{ 'Ranked' | translate }}</small>
          <small> {{ intelPremiumRewardsSidebarData.currentRankIsg }}</small>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-3 d-flex justify-content-between">
      <div class="d-flex flex-column">
        <h6>{{ 'Registration' | translate }}</h6>
        <div>(100 {{ 'credits' | translate }})</div>
      </div>
      <div>{{ premiumRewardsData.registrationCredits | number }}</div>
    </div>
    <div class="mt-3 d-flex justify-content-between">
      <div class="d-flex flex-column">
        <h6>{{ 'Training completion' | translate }}</h6>
        <div>(1,000 {{ 'credits' | translate }})</div>
      </div>
      <div>{{ premiumRewardsData.learnCredits | number }}</div>
    </div>
    <div class="mt-3 d-flex justify-content-between">
      <div class="d-flex flex-column">
        <h6>{{ 'Webinar attendance per event' | translate }}</h6>
        <div>(500 {{ 'credits' | translate }})</div>
      </div>
      <div>{{ premiumRewardsData.webinarCredits | number }}</div>
    </div>
    <div class="mt-3 d-flex justify-content-between">
      <div class="d-flex flex-column">
        <h6>{{ 'User marketing assets' | translate }}</h6>
        <div>(500 {{ 'credits' | translate }})</div>
      </div>
      <div>{{ premiumRewardsData.marketingCredits | number }}</div>
    </div>
    <div class="mt-3 d-flex justify-content-between">
      <div class="d-flex flex-column">
        <h6>{{ 'Submit customer meeting' | translate }}</h6>
        <div>(500 {{ 'credits' | translate }})</div>
      </div>
      <div>{{ premiumRewardsData.customerSubmissionCredits | number }}</div>
    </div>
    <div class="mt-3 d-flex justify-content-between">
      <div class="d-flex flex-column">
        <h6>{{ 'Eligible LEAP sale' | translate }}</h6>
        <div>(50 {{ 'to' | translate }} 1,000 {{ 'credits' | translate }})</div>
      </div>
      <div>{{ premiumRewardsData.salesCredits | number }}</div>
    </div>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-1 mb-3 d-flex justify-content-between">
      <h6>{{ 'Total premium rewards credits' | translate }}</h6>
      <div>{{ premiumRewardsData.totalCredits | number }}</div>
    </div>
  </div>
  <hr class="my-0" />
  <div class="offcanvas-body d-flex flex-column month-draw">
    <div class="my-1 d-flex justify-content-between">
      <h6>
        {{ 'You are ' + (!isInTheCurrentMonth() ? 'not ' : '') + 'in the' | translate }} {{ today | date: 'MMMM' }}
        {{ 'draw' | translate }}!
      </h6>
    </div>
    <div class="my-1 d-flex align-items-center" *ngIf="mode !== 'Isg'">
      <fa-icon
        [icon]="['far', 'check']"
        [size]="'lg'"
        class="text-info"
        *ngIf="this.intelPremiumRewardsSidebarData?.participatedInIdgThisMonth === true"
      ></fa-icon>
      <fa-icon
        [icon]="['far', 'xmark']"
        [size]="'lg'"
        class="text-danger"
        *ngIf="this.intelPremiumRewardsSidebarData?.participatedInIdgThisMonth !== true"
      ></fa-icon>
      <div class="ms-2">{{ 'Client' | translate }}</div>
    </div>
    <div
      class="my-1 d-flex align-items-center"
      *ngIf="mode !== 'Idg'"
      [ngClass]="{ 'mt-0': mode === null || mode === '' }"
    >
      <fa-icon
        [icon]="['far', 'check']"
        [size]="'lg'"
        class="text-info"
        *ngIf="this.intelPremiumRewardsSidebarData?.participatedInIsgThisMonth === true"
      ></fa-icon>
      <fa-icon
        [icon]="['far', 'xmark']"
        [size]="'lg'"
        class="text-danger"
        *ngIf="this.intelPremiumRewardsSidebarData?.participatedInIsgThisMonth !== true"
      ></fa-icon>
      <div class="ms-2">{{ 'Server' | translate }}</div>
    </div>
  </div>
</div>
