<div class="d-flex flex-column justify-content-center wrapper" *ngIf="ssgEmeaSidebarData">
  <div class="offcanvas-body d-flex flex-column">
    <h4 class="mb-0 text-uppercase">{{ 'Summary' | translate }}</h4>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-2 d-flex justify-content-between">
      <h6>{{ 'IDG Services Revenue total' | translate }}</h6>
      <div>{{ ssgEmeaSidebarData.currencySymbol }}{{ ssgEmeaSidebarData.idgServicesRevenueTotal | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between">
      <h6>{{ 'ISG Services Revenue total' | translate }}</h6>
      <div>{{ ssgEmeaSidebarData.currencySymbol }}{{ ssgEmeaSidebarData.isgServicesRevenueTotal | number }}</div>
    </div>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-2 d-flex justify-content-between">
      <h6>{{ 'Total IDG Points' | translate }}</h6>
      <div>{{ ssgEmeaSidebarData.leapidgPointsTotal | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between">
      <h6>{{ 'Total ISG Points' | translate }}</h6>
      <div>{{ ssgEmeaSidebarData.leapisgPointsTotal | number }}</div>
    </div>
  </div>
  <hr />
  <div class="mb-3 offcanvas-body d-flex flex-column">
    <div class="mt-2 d-flex justify-content-between">
      <h6>{{ 'Amount of revenue required for your next 50 IDG LEAP points' | translate }}</h6>
      <div>
        {{ ssgEmeaSidebarData.currencySymbol }}{{ ssgEmeaSidebarData.idgRevenueLeftToGetAwardedAgain | number }}
      </div>
    </div>
    <div class="my-2 d-flex justify-content-between">
      <h6>{{ 'Amount of revenue required for your next 50 ISG LEAP points' | translate }}</h6>
      <div>
        {{ ssgEmeaSidebarData.currencySymbol }}{{ ssgEmeaSidebarData.isgRevenueLeftToGetAwardedAgain | number }}
      </div>
    </div>
  </div>
</div>
