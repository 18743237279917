import { Component, Input } from '@angular/core';

import { PromotionUltimateRaceSummaryData } from '../../../core/model/promotion/promotion-ultimate-race-summary-data';

@Component({
  selector: 'app-ultimate-race-widget',
  templateUrl: './ultimate-race-widget.component.html',
  styleUrls: ['./ultimate-race-widget.component.scss'],
})
export class UltimateRaceWidgetComponent {
  @Input()
  ultimateRaceSummaryData: PromotionUltimateRaceSummaryData;
}
