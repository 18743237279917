<div class="modal-header">
  <div class="modal-title" id="modal-basic-title" [innerHtml]="tncTitle"></div>
</div>
<div class="modal-body">
  <div class="prompt" [innerHtml]="tncBody"></div>

  <div class="w-100 mt-4">
    <form class="d-flex align-items-center justify-content-center flex-column">
      <div class="form-row w-100">
        <div class="d-flex align-items-center terms-form">
          <input type="checkbox" id="tnCs" [(ngModel)]="tnCsChecked" [ngModelOptions]="{ standalone: true }" />
          <label for="tnCs" [innerHtml]="tncAccept"> </label>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="onSubmit()">{{ 'Accept' | translate }}</button>
</div>
