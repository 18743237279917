import { Injectable } from '@angular/core';
import {
  ApiRestService,
  AuthStoreService,
  Checkout,
  DialogNgbService,
  ProductCatalogueRestService,
} from '@motivforce/mx-library-angular';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductCatalogueLenovoLeapRestService extends ProductCatalogueRestService {
  constructor(
    protected override apiRestService: ApiRestService,
    private authStore: AuthStoreService,
    private dialog: DialogNgbService
  ) {
    super(apiRestService, { environment });
  }

  override updateCheckout(checkout: Checkout): Observable<Checkout> {
    if (this.authStore.userSettings) {
      const { country } = this.authStore.userSettings;

      if (country && country.alpha2Code === 'IN') {
        this.dialog.openNotification(['REDEMPTIONS_SUSPENDED_MESSAGE'], 'Important');

        return throwError(() => new Error('Checkout is blocked IN users'));
      }
    }

    return super.updateCheckout(checkout);
  }
}
