import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountSummary,
  AuthStoreService,
  LearnModulesStoreService,
  MxLoggerService,
  ReferenceData,
  SearchResult,
  DialogNgbService,
  RedeemedReward,
  BaseUser,
} from '@motivforce/mx-library-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IsLoadingService } from '@service-work/is-loading';
import * as fileSaver from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { pairwise, filter } from 'rxjs/operators';

import { UserRestService } from '../api/rest/user-rest.service';
import { BusinessRuleMessage } from '../model/core/business-rule-message';
import { CommunicationsPreference } from '../model/core/communications-preference';
import { CompanyClaimedInvoiceResult } from '../model/core/company-claimed-invoice-result';
import { InvoiceSearch } from '../model/core/invoice-search';
import { Profile } from '../model/core/profile';
import { RedemptionSearch } from '../model/core/redemption-search';
import { Region } from '../model/core/region';
import { RewardCollegue } from '../model/core/reward-colleague';
import { Sale } from '../model/core/sale';
import { SetPassword } from '../model/core/set-password';
import { TaxRequirements } from '../model/core/tax-requirements';
import { Transaction } from '../model/core/transaction';
import { TransactionSearch } from '../model/core/transaction-search';
import { TransferPoints } from '../model/core/transfer-points';
import { TransferTransactionSearch } from '../model/core/transfer-transaction-search';
import { LeapPerformanceSummary } from '../model/leap-performance-summary';
import { LenovoLeapProfile } from '../model/lenovo/lenovo-leap-profile';
import { LenovoLeapSalaryBand } from '../model/lenovo/lenovo-leap-salary-band';
import { CustomerMeeting } from '../model/lenovo/submissions/customer-meeting';
import { PromotionTransaction } from '../model/promotion/promotion-transaction';
import { PromotionTransactionSearch } from '../model/promotion/promotion-transaction-search';
import { SpinToWin } from '../model/promotion/spin-to-win';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private readonly _isWEPUser = new BehaviorSubject<boolean>(false);
  readonly isWEPUser$ = this._isWEPUser.asObservable();

  private readonly _accountSummary = new BehaviorSubject<AccountSummary | null>(null);
  readonly accountSummary$ = this._accountSummary.asObservable();

  private readonly _yearAccountSummaries = new BehaviorSubject<AccountSummary[] | null>(null);
  readonly yearAccountSummaries$ = this._yearAccountSummaries.asObservable();

  private readonly _leapPerformanceSummary = new BehaviorSubject<LeapPerformanceSummary | null>(null);
  readonly leapPerformanceSummary$ = this._leapPerformanceSummary.asObservable();

  private readonly _profile = new BehaviorSubject<Profile | null>(null);
  readonly profile$ = this._profile.asObservable();

  private readonly _leapProfile = new BehaviorSubject<LenovoLeapProfile | null>(null);
  readonly leapProfile$ = this._leapProfile.asObservable();

  private readonly _currentTransactionResults = new BehaviorSubject<SearchResult<Transaction> | null>(null);
  readonly currentTransactionResults$ = this._currentTransactionResults.asObservable();

  private readonly _currentPromotionResults = new BehaviorSubject<SearchResult<Transaction> | null>(null);
  readonly currentPromotionResults$ = this._currentPromotionResults.asObservable();

  private readonly _currentRedeemedRewardResults = new BehaviorSubject<SearchResult<RedeemedReward> | null>(null);
  readonly currentRedeemedRewardResults$ = this._currentRedeemedRewardResults.asObservable();

  private readonly _currentBookingsResults = new BehaviorSubject<SearchResult<any> | null>(null);
  readonly currentBookingsResults$ = this._currentBookingsResults.asObservable();

  private readonly _transactionTypes = new BehaviorSubject<ReferenceData[] | null>(null);
  readonly transactionTypes$ = this._transactionTypes.asObservable();

  private readonly _typeaheadVenues = new BehaviorSubject<any[] | null>(null);
  readonly typeaheadVenues$ = this._typeaheadVenues.asObservable();

  private readonly _typeaheadEmails = new BehaviorSubject<string[] | null>(null);
  readonly typeaheadEmails$ = this._typeaheadEmails.asObservable();

  private readonly _paymentIntent = new BehaviorSubject<any | null>(null);
  readonly paymentIntent$ = this._paymentIntent.asObservable();

  private readonly _communicationPreferences = new BehaviorSubject<CommunicationsPreference | null>(null);
  readonly communicationPreferences$ = this._communicationPreferences.asObservable();

  private readonly _userRegion = new BehaviorSubject<Region | null>(null);
  readonly userRegion$ = this._userRegion.asObservable();

  private readonly _businessRules = new BehaviorSubject<BusinessRuleMessage[] | null>(null);
  readonly businessRules$ = this._businessRules.asObservable();

  private readonly _taxRequirements = new BehaviorSubject<TaxRequirements | null>(null);
  readonly taxRequirements$ = this._taxRequirements.asObservable();

  private readonly _salaryBands = new BehaviorSubject<LenovoLeapSalaryBand[] | null>(null);
  readonly salaryBands$ = this._salaryBands.asObservable();

  private readonly _dmrCountries = new BehaviorSubject<string[] | null>(null);
  readonly dmrCountries$ = this._dmrCountries.asObservable();

  private readonly _prcBalance = new BehaviorSubject<any | null>(null);
  readonly prcBalance$ = this._prcBalance.asObservable();

  private readonly _companyUsers = new BehaviorSubject<BaseUser[] | null>(null);
  readonly companyUsers$ = this._companyUsers.asObservable();

  private readonly _emailTemplate = new BehaviorSubject<string | null>(null);
  readonly emailTemplate$ = this._emailTemplate.asObservable();

  private readonly _activeOnlyCompanyUsers = new BehaviorSubject<BaseUser[] | null>(null);
  readonly activeOnlyCompanyUsers$ = this._activeOnlyCompanyUsers.asObservable();

  private readonly _currentTransferResults = new BehaviorSubject<SearchResult<Transaction> | null>(null);
  readonly currentTransferResults$ = this._currentTransferResults.asObservable();

  private readonly _currentInvoicesResults = new BehaviorSubject<SearchResult<CompanyClaimedInvoiceResult> | null>(
    null
  );

  readonly currentInvoicesResults$ = this._currentInvoicesResults.asObservable();

  private readonly _spinToWin = new BehaviorSubject<SpinToWin | null>(null);
  readonly spinToWin$ = this._spinToWin.asObservable();

  private readonly _opportunityTypes = new BehaviorSubject<string[] | null>(null);
  readonly opportunityTypes$ = this._opportunityTypes.asObservable();

  private readonly _currentPromotionTransactionResults = new BehaviorSubject<SearchResult<PromotionTransaction> | null>(
    null
  );

  readonly currentPromotionTransactionResults$ = this._currentPromotionTransactionResults.asObservable();

  private readonly _promotionTransactionTypes = new BehaviorSubject<ReferenceData[] | null>(null);
  readonly promotionTransactionTypes$ = this._promotionTransactionTypes.asObservable();

  private shownBusinessRulesKeys: string[] = [];

  constructor(
    private userRest: UserRestService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogNgbService,
    private learnModuleStore: LearnModulesStoreService,
    private mxLogger: MxLoggerService,
    private authStore: AuthStoreService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.learnModuleStore.currentLearnModule$
      .pipe(pairwise())
      .subscribe(([previousLearnModule, currentLearnModule]) => {
        if (
          previousLearnModule &&
          previousLearnModule.id === currentLearnModule!.id &&
          !previousLearnModule.pointsAwarded &&
          currentLearnModule!.pointsAwarded
        ) {
          this.mxLogger.info('UserStoreService', 'Current Learn Module points awarded. Get user account summary.');
          this.getAccountSummary();
        }
      });

    // Set wep lounge user. This is to show/hide the WEP button in header
    this.authStore.userSettings$.pipe(filter(Boolean)).subscribe((userSettings: any) => {
      const companies = userSettings?.user?.companies;
      if (companies) {
        const isWEPUser = companies.some((company: any) => {
          const value = company?.leapCompanyDetails?.companyGroup?.name === 'WEP';
          return value;
        });
        this._isWEPUser.next(isWEPUser);
      }
    });
  }

  get accountSummary(): AccountSummary | null {
    return this._accountSummary.getValue();
  }

  get yearAccountSummaries(): AccountSummary[] | null {
    return this._yearAccountSummaries.getValue();
  }

  get profile(): Profile | null {
    return this._profile.getValue();
  }

  get leapProfile(): LenovoLeapProfile | null {
    return this._leapProfile.getValue();
  }

  get currentTransactionResults(): SearchResult<Transaction> | null {
    return this._currentTransactionResults.getValue();
  }

  get currentPromotionResults(): SearchResult<Transaction> | null {
    return this._currentPromotionResults.getValue();
  }

  get currentRedeemedRewardResults(): SearchResult<RedeemedReward> | null {
    return this._currentRedeemedRewardResults.getValue();
  }

  get currentBookingsResults(): SearchResult<Sale> | null {
    return this._currentBookingsResults.getValue();
  }

  get transactionTypes(): ReferenceData[] | null {
    return this._transactionTypes.getValue();
  }

  get typeaheadVenues(): any[] | null {
    return this._typeaheadVenues.value;
  }

  get typeaheadEmails(): string[] | null {
    return this._typeaheadEmails.getValue();
  }

  get communicationPreferences(): CommunicationsPreference | null {
    return this._communicationPreferences.getValue();
  }

  get businessRules(): BusinessRuleMessage[] | null {
    return this._businessRules.getValue();
  }

  get taxRequirements(): TaxRequirements | null {
    return this._taxRequirements.getValue();
  }

  get salaryBands(): LenovoLeapSalaryBand[] | null {
    return this._salaryBands.getValue();
  }

  get dmrCountries(): string[] | null {
    return this._dmrCountries.getValue();
  }

  get spinToWin(): SpinToWin | null {
    return this._spinToWin.getValue();
  }

  get opportunityTypes(): string[] | null {
    return this._opportunityTypes.getValue();
  }

  get currentPromotionTransactionResults(): SearchResult<PromotionTransaction> | null {
    return this._currentPromotionTransactionResults.getValue();
  }

  get promotionTransactionTypes(): ReferenceData[] | null {
    return this._promotionTransactionTypes.getValue();
  }

  getAccountSummary(): void {
    this.isLoadingService.add(
      this.userRest.getAccountSummary().subscribe((accountSummary: AccountSummary) => {
        this._accountSummary.next(accountSummary);
      })
    );
  }

  getYearAccountSummaries(): void {
    this.isLoadingService.add(
      this.userRest.getYearAccountSummaries().subscribe((yearAccountSummaries: AccountSummary[]) => {
        this._yearAccountSummaries.next(yearAccountSummaries);
      })
    );
  }

  getLeapPerformanceSummary(): void {
    this.isLoadingService.add(
      this.userRest.getLeapPerformanceSummary().subscribe((summary) => {
        this._leapPerformanceSummary.next(summary);
      })
    );
  }

  getProfile(): void {
    this.isLoadingService.add(
      this.userRest.getProfile().subscribe((profile: Profile) => {
        this._profile.next(profile);
      })
    );
  }

  getLenovoLeapProfile(): void {
    this.isLoadingService.add(
      this.userRest.getLenovoLeapProfile().subscribe((leapProfile: LenovoLeapProfile) => {
        this._leapProfile.next(leapProfile);
      })
    );
  }

  updateLenovoLeapProfile(leapProfile: LenovoLeapProfile): void {
    this.isLoadingService.add(
      this.userRest.updateLenovoLeapProfile(leapProfile).subscribe((responseLeapProfile) => {
        this._leapProfile.next(responseLeapProfile);
        this.dialog.openNotification(['You have updated your Leap profile successfully.'], '');
      })
    );
  }

  searchTransactions(searchCriteria: TransactionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchTransactions(searchCriteria).subscribe((searchResult: SearchResult<Transaction>) => {
        searchResult.results = searchResult.results.filter(
          (transaction) => !transaction.pointsType.toLowerCase().includes('credits')
        );
        this._currentTransactionResults.next(searchResult);
      })
    );
  }

  getTransactionTypes(): void {
    if (this.transactionTypes) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getTransactionTypes().subscribe((transactionTypes: ReferenceData[]) => {
        const filteredTypes = transactionTypes.filter((type) => !type.name.toLowerCase().includes('credits'));
        this._transactionTypes.next(filteredTypes);
      })
    );
  }

  searchPromotions(searchCriteria: TransactionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchPromotions(searchCriteria).subscribe((searchResult: SearchResult<Transaction>) => {
        this._currentPromotionResults.next(searchResult);
      })
    );
  }

  searchRedeemedRewards(searchCriteria: RedemptionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchRedeemedRewards(searchCriteria).subscribe((searchResult: SearchResult<RedeemedReward>) => {
        this._currentRedeemedRewardResults.next(searchResult);
      })
    );
  }

  getCommunicationPreferences(): void {
    this.isLoadingService.add(
      this.userRest.getCommunicationPreferences().subscribe((communicationPreferences: CommunicationsPreference) => {
        this._communicationPreferences.next(communicationPreferences);
      })
    );
  }

  updateCommunicationPreferences(communicationPreferences: CommunicationsPreference): void {
    this.isLoadingService.add(
      this.userRest.updateCommunicationPreferences(communicationPreferences).subscribe(() => {
        this.dialog.openNotification(['You have updated your communication preferences.'], '');
      })
    );
  }

  acceptTermsConditions(): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.acceptTermsConditions().subscribe({
          next: () => {
            this.modalService.dismissAll();
            this.authStore.getUserSettings();
            resolve(true);
          },
          error: () => resolve(false),
        })
      );
    });
  }

  setPassword(password: SetPassword): void {
    this.isLoadingService.add(
      this.userRest.setPassword(password).subscribe(() => {
        this.dialog.openNotification(['You have updated your password successfully.'], '');
        this.router.navigate(['/auth/login']);
      })
    );
  }

  createCheckoutSession(form: any): void {
    this.isLoadingService.add(
      this.userRest.createCheckoutSession(form).subscribe((paymentIntent) => {
        this._paymentIntent.next(paymentIntent);
      })
    );
  }

  rewardColleague(reward: RewardCollegue): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.rewardColleague(reward).subscribe({
          next: () => resolve(true),
          error: () => resolve(false),
        })
      );
    });
  }

  getPrcBalance(): void {
    this.isLoadingService.add(
      this.userRest.getPrcBalance().subscribe((prcBalance: any) => {
        this._prcBalance.next(prcBalance);
      })
    );
  }

  getCompanyUsers(): void {
    this.isLoadingService.add(
      this.userRest.getCompanyUsers().subscribe((companyUsers: any[]) => {
        this._companyUsers.next(companyUsers);
      })
    );
  }

  getActiveOnlyCompanyUsers(): void {
    this.isLoadingService.add(
      this.userRest.getActiveCompanyUsers().subscribe((companyUsers: any[]) => {
        this._activeOnlyCompanyUsers.next(companyUsers);
      })
    );
  }

  transferPoints(transferPoints: TransferPoints): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.transferPoints(transferPoints).subscribe({
          next: () => {
            this.dialog.openNotification(['You have transferred points successfully.'], '');
            resolve(true);
          },
          error: () => resolve(false),
        })
      );
    });
  }

  searchTransfers(searchCriteria: TransferTransactionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchTransfers(searchCriteria).subscribe((searchResult: SearchResult<Transaction>) => {
        this._currentTransferResults.next(searchResult);
      })
    );
  }

  searchInvoices(searchCriteria: InvoiceSearch): void {
    this.isLoadingService.add(
      this.userRest
        .searchInvoices(searchCriteria)
        .subscribe((searchResult: SearchResult<CompanyClaimedInvoiceResult>) => {
          this._currentInvoicesResults.next(searchResult);
        })
    );
  }

  downloadInvoices(searchCriteria: InvoiceSearch): void {
    this.isLoadingService.add(
      this.userRest.downloadInvoices(searchCriteria).subscribe((result) => {
        if (result.content && result.contentType) {
          const blob: any = new Blob([result.content], { type: result.contentType });
          fileSaver.saveAs(blob, result.fileName);
        } else {
          this.mxLogger.error('ProductPointStoreService', `Missing result content or resultcontentType`);
        }
      })
    );
  }

  setUserRegion(region: Region): void {
    this._userRegion.next(region);
  }

  typeaheadUserSearchEmail(term: string): void {
    this.isLoadingService.add(
      this.userRest.searchAvailableEmails(term).subscribe((emails: any[]) => {
        this._typeaheadEmails.next(emails.map((item) => item.email));
      })
    );
  }

  getBusinessRules(): void {
    this.isLoadingService.add(
      this.userRest.getBusinessRules().subscribe((businessRules: BusinessRuleMessage[]) => {
        this._businessRules.next(businessRules);
      })
    );
  }

  getTaxRequirements(): void {
    this.isLoadingService.add(
      this.userRest.getTaxRequirements().subscribe((taxRequirements: TaxRequirements) => {
        this._taxRequirements.next(taxRequirements);
      })
    );
  }

  getSalaryBands(): void {
    if (this.salaryBands) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getSalaryBands().subscribe((salaryBands: LenovoLeapSalaryBand[]) => {
        this._salaryBands.next(salaryBands);
      })
    );
  }

  getDmrCountries(): void {
    if (this.dmrCountries) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getDmrCountries().subscribe((dmrCountries: string[]) => {
        this._dmrCountries.next(dmrCountries);
      })
    );
  }

  showBusinessRule(message: BusinessRuleMessage): void {
    if (message.businessRuleType === 'Cap' && !this.shownBusinessRulesKeys.includes(message.displayKey)) {
      this.dialog
        .openNotification([message], 'Important')
        .then(() => this.shownBusinessRulesKeys.push(message.displayKey));
    }
  }

  getSpinToWin(): void {
    this.isLoadingService.add(
      this.userRest.getSpinToWin().subscribe((spinToWin) => {
        this._spinToWin.next(spinToWin);
      })
    );
  }

  getEmailTemplate(entityId: string): void {
    this.isLoadingService.add(
      this.userRest.getEmailTemplate(entityId).subscribe((emailTemplate) => {
        this._emailTemplate.next(emailTemplate);
      })
    );
  }

  getOpportunityTypes(): void {
    if (this.opportunityTypes) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getOpportunityTypes().subscribe((opportunityTypes: string[]) => {
        this._opportunityTypes.next(opportunityTypes);
      })
    );
  }

  createCustomerMeeting(customerMeeting: CustomerMeeting): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.createCustomerMeeting(customerMeeting).subscribe({
          next: () => resolve(true),
          error: () => resolve(false),
        })
      );
    });
  }

  searchPromotionTransactions(searchCriteria: PromotionTransactionSearch): void {
    this.isLoadingService.add(
      this.userRest
        .searchPromotionTransactions(searchCriteria)
        .subscribe((searchResult: SearchResult<PromotionTransaction>) => {
          this._currentPromotionTransactionResults.next(searchResult);
        })
    );
  }

  getPromotionTransactionTypes(): void {
    if (this.promotionTransactionTypes) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getTransactionTypes().subscribe((promotionTransactionTypes: ReferenceData[]) => {
        this._promotionTransactionTypes.next(promotionTransactionTypes);
      })
    );
  }

  unsubscribeEmail(entityId: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.unsubscribeEmail(entityId).subscribe({
          next: () => {
            resolve(true);
          },
          error: () => resolve(false),
        })
      );
    });
  }
}
