import { Component } from '@angular/core';

import { CustomListComponent } from '../custom-list/custom-list.component';

@Component({
  selector: 'app-custom-product-point-list',
  templateUrl: './custom-product-point-list.component.html',
  styleUrls: ['./custom-product-point-list.component.scss'],
})
export class CustomProductPointListComponent extends CustomListComponent {
  override itemsPerPageDisplayKey = `${this.translate.instant('Items per page')}:`;
  override entriesDisplayKey = '';
}
