import { Injectable } from '@angular/core';
import { UrlTree, CanActivate, Router } from '@angular/router';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { filter, map, Observable, of, switchMap } from 'rxjs';

import { UserStoreService } from '../store/user-store.service';

function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

@Injectable({
  providedIn: 'root',
})
export class TaxRequirementsCompletedGuard implements CanActivate {
  constructor(private router: Router, private userStore: UserStoreService, private authStore: AuthStoreService) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authStore.isRegularUser$.pipe(
      filter(isNonNull),
      switchMap((isRegularUser: boolean) => {
        if (isRegularUser) {
          return this.userStore.taxRequirements$.pipe(
            filter(Boolean),
            map((taxRequirements) => {
              if (taxRequirements.taxRequirementsCompleted) {
                return true;
              }

              this.router.navigateByUrl('/my-leap');
              return false;
            })
          );
        }

        return of(true);
      })
    );
  }
}
