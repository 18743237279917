import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BankingAccountDetail, BankingStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-mastercard-account-information',
  templateUrl: './mastercard-account-information.component.html',
  styleUrls: ['./mastercard-account-information.component.scss'],
})
export class MastercardAccountInformationComponent implements OnInit {
  cardApplicationStatus$ = this.bankingStore.applicationStatus$.pipe(filter(Boolean));
  isVirtual$ = this.bankingStore.currentUserAccountDetailVirtual$.pipe(untilDestroyed(this));

  userAccountDetail: BankingAccountDetail;

  currencySymbol: string;

  expiring = false;
  expired = false;
  hasPoints = false;
  hasBalance = false;

  // 28 days offset
  expiryDateOffset = new Date(Date.now() + 1000 * 60 * 60 * 24 * 28);

  constructor(private bankingStore: BankingStoreService) {}

  ngOnInit(): void {
    this.bankingStore.currentUserAccountDetail$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((userAccountDetail) => {
        this.userAccountDetail = userAccountDetail;

        this.currencySymbol = getCurrencySymbol(userAccountDetail.currency, 'narrow');

        const today = new Date();
        const expiryDate = new Date(this.userAccountDetail.security.expiryDate);

        this.expired = expiryDate.getTime() < today.getTime();
        this.expiring =
          expiryDate.getTime() > today.getTime() && expiryDate.getTime() < this.expiryDateOffset.getTime();

        if (this.expired || this.expiring) {
          this.hasBalance = parseInt(this.userAccountDetail.availableBalance, 10) > 0;
        }
      });
  }

  viewCard(): void {
    this.bankingStore.viewCard('assets/image/mastercard-front.png', 'assets/image/mastercard-back.png');
  }
}
