<div class="d-flex flex-column justify-content-center wrapper">
  <div class="offcanvas-body d-flex flex-column">
    <h4 class="text-uppercase">{{ 'Points summary' | translate }}</h4>

    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Learn' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.learnPoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Sell' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.salePoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Promotion' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.promoPoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Redeemed' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.redeemedPoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Transferred' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.transferredPoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Expired' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.expiredPoints | number }}</div>
    </div>
    <div class="mt-2 d-flex justify-content-between align-items-center">
      <h6>{{ 'Total' | translate }}</h6>
      <div>{{ (accountSummary$ | async)?.availableBalance | number }}</div>
    </div>

    <div
      class="mt-2 py-2 d-flex justify-content-between align-items-center"
      *ngIf="previousYearsAccountSummary.length > 0 && previousYearsAccountSummary[0].availableBalance > 0"
    >
      <h6>
        {{ previousYears[0] }}{{ ' points available to redeem until ' | translate
        }}{{ previousYearsAccountSummary[0].expirationDate | date }}
      </h6>
      <div>{{ previousYearsAccountSummary[0].availableBalance | number }}</div>
    </div>

    <div
      class="mt-2 py-2 d-flex justify-content-between align-items-center"
      *ngIf="previousYearsAccountSummary.length > 0"
    >
      <h6 class="me-5">
        {{ currentYear }}{{ ' points available to redeem until ' | translate
        }}{{ (accountSummary$ | async)?.expirationDate | date }}
      </h6>
      <div>{{ (accountSummary$ | async)?.availableBalance | number }}</div>
    </div>
  </div>

  <div class="offcanvas-body d-flex flex-column business-rule" *ngFor="let rule of businessRules$ | async">
    <div class="mt-2 py-2 d-flex justify-content-start align-items-start text-danger" *ngIf="rule.displayKey">
      <div class="warning-icon me-2">
        <fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon>
      </div>
      <div class="me-4">
        {{ rule.displayKey | translate: rule.parameters }}
      </div>
    </div>
  </div>
</div>
