import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStoreService, MxLoggerService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-logout-container',
  templateUrl: './logout-container.component.html',
  styleUrls: ['./logout-container.component.scss'],
})
export class LogoutContainerComponent implements OnInit {
  constructor(private authStore: AuthStoreService, private mxLogger: MxLoggerService, private router: Router) {}

  ngOnInit(): void {
    this.authStore.authenticatedUser$.pipe(untilDestroyed(this), skip(1), take(1)).subscribe((authenticatedUser) => {
      this.mxLogger.debug('LogoutContainerComponent', 'ngOnInit() authenticatedUser=', authenticatedUser);

      if (authenticatedUser) {
        this.authStore.authenticatedUser$.pipe(untilDestroyed(this), skip(1), take(1)).subscribe(() => {
          this.mxLogger.info('LogoutContainerComponent', 'Sign out');
          this.router.navigate(['/']);
        });

        this.authStore.signOut();
      } else {
        this.router.navigate(['/']);
      }
    });

    this.authStore.getCurrentAuthenticatedUser();
  }
}
