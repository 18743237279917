<lib-generic-list
  #genericList
  [dataProvider]="dataProvider"
  [totalCount]="totalCount"
  [columnsProvider]="columnsProvider"
  [noResultsMessageDisplayKey]="noResultsMessageDisplayKey"
  [itemsPerPageDisplayKey]="itemsPerPageDisplayKey"
  [entriesDisplayKey]="
    totalCount
      ? (genericList.page - 1) * genericList.pageSize +
        1 +
        ' - ' +
        genericList.page * genericList.pageSize +
        ' ' +
        ('of' | translate) +
        ' ' +
        totalCount
      : ''
  "
  [serverPaging]="true"
  [defaultPagination]="false"
  [tableStriped]="false"
  [actionsColumnLeft]="false"
  (stateChanged)="stateChanged.emit($event)"
>
  <ng-container *libGenericListCustomItemRenderer="'serverDate'; let row">
    <div class="d-flex flex-column">
      <div>{{ row.info.server_date | date: (programDateFormat ? programDateFormat : 'dd-MM-yyyy') }}</div>
      <div *ngIf="row.fee && row.fee.id">
        {{ row.info.server_date | date: (programDateFormat ? programDateFormat : 'dd-MM-yyyy') }}
      </div>
    </div>
  </ng-container>
  <ng-container *libGenericListCustomItemRenderer="'notes'; let row">
    <div class="d-flex flex-column">
      <div>{{ row.info.notes }}</div>
      <div *ngIf="row.fee && row.fee.id">
        {{ row.fee.notes }}
      </div>
    </div>
  </ng-container>
  <ng-container *libGenericListCustomItemRenderer="'debit'; let row">
    <div class="d-flex flex-column">
      <div [ngClass]="{ 'h-50': row.fee && row.fee.id }">
        <ng-container *ngIf="row.info.operation === 'Debit'">{{ row.info.amount }}</ng-container>
      </div>
      <div *ngIf="row.fee && row.fee.id">
        <ng-container *ngIf="row.fee.operation === 'Debit'">{{ row.fee.amount }}</ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *libGenericListCustomItemRenderer="'credit'; let row">
    <div class="d-flex flex-column">
      <div [ngClass]="{ 'h-50': row.fee && row.fee.id }">
        <ng-container *ngIf="row.info.operation === 'Credit'">{{ row.info.amount }}</ng-container>
      </div>
      <div *ngIf="row.fee && row.fee.id">
        <ng-container *ngIf="row.fee.operation === 'Credit'">{{ row.fee.amount }}</ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *libGenericListCustomItemRenderer="'balance'; let row">
    <div class="d-flex flex-column">
      <div>{{ row.info.balance }}</div>
      <div *ngIf="row.fee && row.fee.id">
        {{ row.fee.balance }}
      </div>
    </div>
  </ng-container>
</lib-generic-list>
