import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, TranslateResolver } from '@motivforce/mx-library-angular';

import { LoginRedirectContainerComponent } from './auth/components/login-redirect-container/login-redirect-container.component';
import { RedeemBlockedGuard } from './core/guards/redeem-blocked.guard';
import { TaxRequirementsCompletedGuard } from './core/guards/tax-requirements-completed.guard';
import { TermsAndConditionsAcceptedGuard } from './core/guards/terms-and-conditions-accepted.guard';
import { TierAllowedGuard } from './core/guards/tier-allowed.guard';
import { UserBlockedGuard } from './core/guards/user-blocked.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'sso/consume',
    component: LoginRedirectContainerComponent,
    canActivate: [],
    data: {
      hideHeaderFooter: true,
    },
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [],
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
    canActivate: [],
  },
  {
    path: 'thank-you',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'home',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Home') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard, UserBlockedGuard, TaxRequirementsCompletedGuard, TierAllowedGuard],
  },
  {
    path: 'terms-and-conditions',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Terms and conditions') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'support',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Support') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [AuthGuard, UserBlockedGuard],
  },
  {
    path: 'rewards-catalogue',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Reward catalogue') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./product-catalogue/product-catalogue.module').then((m) => m.ProductCatalogueModule),
    canActivate: [
      AuthGuard,
      UserBlockedGuard,
      TermsAndConditionsAcceptedGuard,
      TaxRequirementsCompletedGuard,
      RedeemBlockedGuard,
      TierAllowedGuard,
    ],
  },
  {
    path: 'points-statement',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('My Points Statement') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./account-summary/account-summary.module').then((m) => m.AccountSummaryModule),
    canActivate: [
      AuthGuard,
      UserBlockedGuard,
      TermsAndConditionsAcceptedGuard,
      TaxRequirementsCompletedGuard,
      TierAllowedGuard,
    ],
  },
  {
    path: 'account-summary-statement',
    loadChildren: () => import('./account-summary/account-summary.module').then((m) => m.AccountSummaryModule),
    canActivate: [
      AuthGuard,
      UserBlockedGuard,
      TermsAndConditionsAcceptedGuard,
      TaxRequirementsCompletedGuard,
      TierAllowedGuard,
    ],
  },
  {
    path: 'promotions',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('PROMOTIONS') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./promotion/promotion.module').then((m) => m.PromotionModule),
    canActivate: [
      AuthGuard,
      UserBlockedGuard,
      TermsAndConditionsAcceptedGuard,
      TaxRequirementsCompletedGuard,
      TierAllowedGuard,
    ],
  },
  {
    path: 'program-overview',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Program overview') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./program-overview/program-overview.module').then((m) => m.ProgramOverviewModule),
    canActivate: [
      AuthGuard,
      UserBlockedGuard,
      TermsAndConditionsAcceptedGuard,
      TaxRequirementsCompletedGuard,
      TierAllowedGuard,
    ],
  },
  {
    path: 'customer-reference-submissions',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Lenovo 360 Customer reference submissions') },
    resolve: { translate: TranslateResolver },
    loadChildren: () =>
      import('./isg-customer-reference/isg-customer-reference.module').then((m) => m.IsgCustomerReferenceModule),
    canActivate: [
      AuthGuard,
      UserBlockedGuard,
      TermsAndConditionsAcceptedGuard,
      TaxRequirementsCompletedGuard,
      TierAllowedGuard,
    ],
  },
  {
    path: 'take-a-course',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Take a course') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./learn-modules/learn-modules.module').then((m) => m.LearnModulesModule),
    canActivate: [
      AuthGuard,
      UserBlockedGuard,
      TermsAndConditionsAcceptedGuard,
      TaxRequirementsCompletedGuard,
      TierAllowedGuard,
    ],
  },
  {
    path: 'workstation-expert-partner-lounge',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Workstation Expert Partner Lounge') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./wep-lounge/wep-lounge.module').then((m) => m.WEPLoungeModule),
    canActivate: [AuthGuard, UserBlockedGuard, TierAllowedGuard],
  },
  {
    path: 'my-leap',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('My LEAP') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./my-leap/my-leap.module').then((m) => m.MyLeapModule),
    canActivate: [AuthGuard, UserBlockedGuard, TierAllowedGuard],
  },
  {
    path: 'claim-a-sale',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Claim a sale') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./sales-claiming/sales-claiming.module').then((m) => m.SalesClaimingModule),
    canActivate: [
      AuthGuard,
      UserBlockedGuard,
      TermsAndConditionsAcceptedGuard,
      TaxRequirementsCompletedGuard,
      TierAllowedGuard,
    ],
  },
  {
    path: 'certification-validation',
    loadChildren: () => import('./certification/certification.module').then((m) => m.CertificationModule),
    canActivate: [],
  },
  {
    path: 'company-account',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Company account') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./company-account/company-account.module').then((m) => m.CompanyAccountModule),
    canActivate: [AuthGuard, UserBlockedGuard, TermsAndConditionsAcceptedGuard, TierAllowedGuard],
  },
  {
    path: 'event-referral',
    loadChildren: () => import('./event-referral/event-referral.module').then((m) => m.EventReferralModule),
    canActivate: [],
  },
  {
    path: 'email-view',
    loadChildren: () => import('./email-view/email-view.module').then((m) => m.EmailViewModule),
    canActivate: [],
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./unsubscribe/unsubscribe.module').then((m) => m.UnsubscribeModule),
    canActivate: [],
  },
  {
    path: 'gold-or-platinum-tier-allowed',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'program-unavailable',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
