import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, UnauthGuard } from '@motivforce/mx-library-angular';

import { LoginContentTemplateComponent } from './components/login-content-template/login-content-template.component';
import { LoginImpersonateContainerComponent } from './components/login-impersonate-container/login-impersonate-container.component';
import { LoginRedirectContainerComponent } from './components/login-redirect-container/login-redirect-container.component';
import { LogoutContainerComponent } from './components/logout-container/logout-container.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login/admin',
    component: LoginContentTemplateComponent,
    canActivate: [UnauthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'logout',
    component: LogoutContainerComponent,
    canActivate: [],
  },

  {
    path: 'forgot-password',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'set-password',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [UnauthGuard],
  },
  {
    path: 'redirect',
    component: LoginRedirectContainerComponent,
    canActivate: [],
    data: {
      hideHeaderFooter: true,
    },
  },
  {
    path: 'impersonate',
    component: LoginImpersonateContainerComponent,
    canActivate: [AuthGuard],
    data: {
      hideHeaderFooter: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
