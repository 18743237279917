import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentStoreService, BannerModule } from '@motivforce/mx-library-angular';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { ForgotPasswordContentTemplateComponent } from './components/forgot-password-content-template/forgot-password-content-template.component';
import { LoginContentTemplateComponent } from './components/login-content-template/login-content-template.component';
import { LoginImpersonateContainerComponent } from './components/login-impersonate-container/login-impersonate-container.component';
import { LoginRedirectContainerComponent } from './components/login-redirect-container/login-redirect-container.component';
import { LogoutContainerComponent } from './components/logout-container/logout-container.component';
import { SetPasswordContentTemplateComponent } from './components/set-password-content-template/set-password-content-template.component';

@NgModule({
  declarations: [
    ForgotPasswordContentTemplateComponent,
    LoginContentTemplateComponent,
    LoginRedirectContainerComponent,
    SetPasswordContentTemplateComponent,
    LoginImpersonateContainerComponent,
    LogoutContainerComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    BannerModule,
  ],
  exports: [LoginRedirectContainerComponent],
})
export class AuthModule {
  constructor(contentStore: ContentStoreService) {
    contentStore.registerTemplate('forgotPasswordTemplate', ForgotPasswordContentTemplateComponent);
    contentStore.registerTemplate('loginTemplate', LoginContentTemplateComponent);
    contentStore.registerTemplate('setPasswordTemplate', SetPasswordContentTemplateComponent);
  }
}
