import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStoreService, MxLoggerService, DialogNgbService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-login-impersonate-container',
  templateUrl: './login-impersonate-container.component.html',
  styleUrls: ['./login-impersonate-container.component.scss'],
})
export class LoginImpersonateContainerComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStore: AuthStoreService,
    private mxLogger: MxLoggerService,
    private dialog: DialogNgbService
  ) {}

  ngOnInit(): void {
    const { impersonateUserId } = this.route.snapshot.queryParams;

    this.mxLogger.info(
      'LoginImpersonateContainerComponent',
      'Trying to impersonate user. impersonateUserId=',
      impersonateUserId
    );

    this.authStore.stopImpersonation();

    this.authStore.authenticatedUser$.pipe(untilDestroyed(this), skip(1), take(1)).subscribe((authUser) => {
      if (!authUser) {
        this.router.navigate(['/auth/login/admin']);
      }
    });

    this.authStore.userSettings$.pipe(untilDestroyed(this), skip(1), take(1)).subscribe((settings) => {
      if (settings) {
        try {
          this.authStore.setImpersonateUserId(impersonateUserId);
        } catch (error: any) {
          this.dialog.openError([String(error)]);
        }

        this.authStore.userSettings$.pipe(skip(1), take(1)).subscribe(() => {
          this.router.navigate(['/']);
        });

        this.authStore.getUserSettings();
      }
    });

    this.authStore.getCurrentAuthenticatedUser();
  }
}
