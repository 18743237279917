import { Component, Input } from '@angular/core';

import { CustomListComponent } from '../custom-list/custom-list.component';

@Component({
  selector: 'app-custom-sales-claiming-list',
  templateUrl: './custom-sales-claiming-list.component.html',
  styleUrls: ['./custom-sales-claiming-list.component.scss'],
})
export class CustomSalesClaimingListComponent extends CustomListComponent {
  override itemsPerPageDisplayKey = `${this.translate.instant('Items per page')}:`;
  override entriesDisplayKey = '';

  private _allSelectable = false;

  @Input()
  get allSelectable(): boolean {
    return this._allSelectable;
  }

  set allSelectable(value: boolean) {
    this._allSelectable = value;
  }

  @Input()
  override get dataProvider(): any[] {
    return super.dataProvider;
  }

  override set dataProvider(value: any[]) {
    super.dataProvider = value;

    this.allSelectable = false;

    if (value) {
      value.forEach((item) => {
        this.allSelectable = this.allSelectable || this.itemSelectableFunction(item);
      });
    }
  }
}
