// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { NgxLoggerLevel } from 'ngx-logger';

import awsmobile from '../aws-exports-dev';

export const environment = {
  production: false,
  loggerLevel: NgxLoggerLevel.TRACE,
  headerFixed: true,
  dynamicHeaderMenu: true,
  dynamicFooterMenus: true,
  oidc: {
    provider: 'LenovoSSO',
  },
  api: {
    cms: 'https://devapi.motivforcem9.com/cms',
    core: {
      baseUrl: 'https://api-dev.motivforcem9.com/core/api',
      bankingUrl: 'https://dev-mc.motivforcemx.com/core/api',
      path: {
        member: 'members',
        client: 'clients/lenovo/leap',
      },
    },
  },
  amplify: awsmobile,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
