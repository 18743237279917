import { Component } from '@angular/core';
import { ContentGqlService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  template: '',
})
export class AbstractTncComponent {
  tncUrl: string;
  constructor(private contentGql: ContentGqlService) {}

  getTermsAndConditionUrl(language: string): void {
    const siteUrl = new URL(window.location.href);
    const tncPath = `${siteUrl.origin}/menu-terms-and-conditions`;

    this.contentGql
      .getContentByUrlPath(tncPath, language, true)
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((content) => {
        const urlField = content.fields.find((f: any) => f.name === 'Url');
        const tncUrl = urlField!.value.value;
        if (tncUrl) {
          // Remove html tags coming from CMS
          this.tncUrl = tncUrl.replace(/(<([^>]+)>)/gi, '');
        }
      });
  }
}
