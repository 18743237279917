import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-dynamic-checkbox-group',
  templateUrl: './dynamic-checkbox-group.component.html',
  styleUrls: ['./dynamic-checkbox-group.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class DynamicCheckboxGroupComponent {
  @Input() label: string;
  @Input() formArrayName!: string;
  @Input() items: any[][];

  @Output() checkboxChange = new EventEmitter<{ item: any; target: any }>();

  onCheckboxChange(item: any, target: any): void {
    if (this.checkboxChange) {
      this.checkboxChange.emit({ item, target });
    }
  }
}
