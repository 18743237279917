<div class="p-4">
  <h4>{{ 'ACCOUNT INFORMATION' | translate }}</h4>
  <ng-container *ngIf="userAccountDetail">
    <div class="mt-4 d-flex justify-content-between align-items-center">
      <h6>{{ 'Account Id' | translate }}</h6>
      <div>{{ userAccountDetail.id }}</div>
    </div>
    <div class="mt-4 d-flex justify-content-between align-items-center">
      <h6>{{ 'Card number' | translate }}</h6>
      <div>{{ userAccountDetail.number }}</div>
    </div>
    <div class="mt-4 d-flex justify-content-between align-items-center">
      <h6>{{ 'Account name' | translate }}</h6>
      <div>{{ userAccountDetail.name }}</div>
    </div>
    <div class="mt-4 d-flex justify-content-between align-items-center">
      <h6>{{ 'Account status' | translate }}</h6>
      <div>{{ userAccountDetail.status }}</div>
    </div>
    <div class="mt-4 d-flex justify-content-between align-items-center">
      <h6>{{ 'Currency' | translate }}</h6>
      <div>{{ userAccountDetail.currency }}</div>
    </div>
    <div class="mt-4 d-flex justify-content-between align-items-center">
      <h6>{{ 'Balance' | translate }}</h6>
      <div>{{ currencySymbol }} {{ userAccountDetail.balance }}</div>
    </div>
    <div class="mt-4 d-flex justify-content-between align-items-center">
      <h6>{{ 'Available balance' | translate }}</h6>
      <div>{{ currencySymbol }} {{ userAccountDetail.availableBalance }}</div>
    </div>

    <ng-container
      *ngIf="
        (expired && (hasPoints || hasBalance)) ||
        (expiring && hasBalance && !(cardApplicationStatus$ | async)?.activationRequired)
      "
    >
      <div class="mt-3 panel-buttons">
        <button class="btn btn-primary" [routerLink]="'/my-leap/my-lenovo-mastercard/request-replacement'">
          {{ 'Report expired' | translate }}
        </button>
      </div>
      <div class="mt-2">
        {{ 'Report expired card explanation' | translate }}
      </div>
    </ng-container>
    <ng-container *ngIf="(cardApplicationStatus$ | async)?.activeCardPresent">
      <div class="mt-3 panel-buttons">
        <button class="btn btn-primary" [routerLink]="'/my-leap/my-lenovo-mastercard/reupload-documents'">
          {{ 'Re-upload documents' | translate }}
        </button>
      </div>
      <div class="mt-3 panel-buttons">
        <button class="btn btn-outline-primary" [routerLink]="'/my-leap/my-lenovo-mastercard/report-missing'">
          {{ 'Report missing' | translate }}
        </button>
      </div>
      <div class="mt-3 panel-buttons" *ngIf="(isVirtual$ | async) !== true">
        <button class="btn btn-outline-primary" [routerLink]="'/my-leap/my-lenovo-mastercard/pin'">
          {{ 'View PIN' | translate }}
        </button>
      </div>
      <div class="mt-3 panel-buttons" *ngIf="(isVirtual$ | async) === true">
        <button class="btn btn-outline-primary" (click)="viewCard()">
          {{ 'View card' | translate }}
        </button>
      </div>
      <div class="mt-3 panel-buttons">
        <button
          class="btn btn-outline-primary"
          [routerLink]="'/rewards-catalogue/list'"
          [queryParams]="{ mastercardReload: 1 }"
        >
          {{ 'Reload card' | translate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="(cardApplicationStatus$ | async)?.activationRequired">
      <div class="mt-3 panel-buttons">
        <button class="btn btn-primary" [routerLink]="'/my-leap/my-lenovo-mastercard/activate-card'">
          {{ 'Activate card' | translate }}
        </button>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!userAccountDetail">
    <div class="mt-3 panel-buttons">
      <button class="btn btn-primary" [routerLink]="'/my-leap/my-lenovo-mastercard/activate-card'">
        {{ 'Activate card' | translate }}
      </button>
    </div>
  </ng-container>
</div>
