<app-modal-open-padding-wrapper>
  <header #header [ngClass]="{ fixed: headerFixed }">
    <div class="w-100 logos-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-0 d-flex justify-content-start align-items-center">
            <div class="logo">
              <img
                src="https://mmicdn.s3.eu-central-1.amazonaws.com/LenovoLeapMX/WebsiteImages/Logos/LEAP+logo.jpg"
                alt="Lenovo LEAP"
              />
            </div>
            <div class="navbar" *ngIf="(authenticatedUser$ | async) !== null">
              <div class="h-100 flex-fill">
                <div class="m-0 w-100 h-100 row">
                  <div class="p-0 col-12">
                    <div class="h-100 d-none d-lg-block navbar-wrapper" *ngIf="headerMenu">
                      <div
                        class="h-100 lower-nav mx-5 px-lg-5 px-xxl-0 d-flex justify-content-start align-items-center"
                      >
                        <ng-container *ngFor="let item of headerMenu.items">
                          <ng-container *ngIf="item.items && item.items.length === 0">
                            <div
                              class="h-100 d-flex justify-content-center align-items-center navbar-item px-3 mx-1"
                              routerLinkActive="active"
                            >
                              <a [routerLink]="item.url" *ngIf="!item.url.includes('http')">
                                <span class="nav-label" [innerHTML]="item.label"></span>
                              </a>
                              <a [href]="item.url" target="_blank" *ngIf="item.url.includes('http')">
                                <span class="nav-label" [innerHTML]="item.label"></span>
                              </a>
                            </div>
                          </ng-container>

                          <ng-container *ngIf="item.items && item.items.length > 0">
                            <div class="h-100 navbar-item dropdown my-accounts">
                              <a
                                [routerLink]="item.url"
                                routerLinkActive="active"
                                class="dropdown-toggle"
                                data-toggle="dropdown"
                                *ngIf="!item.url.includes('http')"
                                >{{ item.label }}</a
                              >
                              <a
                                [href]="item.url"
                                target="_blank"
                                class="dropdown-toggle"
                                data-toggle="dropdown"
                                *ngIf="item.url.includes('http')"
                                >{{ item.label }}</a
                              >
                              <ul class="dropdown-menu">
                                <ng-container *ngFor="let subItem of item.items">
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      [routerLink]="subItem.url"
                                      *ngIf="!subItem.url.includes('http')"
                                      >{{ subItem.label }}</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      [href]="subItem.url"
                                      target="_blank"
                                      *ngIf="subItem.url.includes('http')"
                                      >{{ subItem.label }}</a
                                    >
                                  </li>
                                </ng-container>
                              </ul>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>

                    <div class="d-lg-none w-100 h-100 navbar-wrapper flex-row justify-content-end align-items-center">
                      <div class="d-flex justify-content-end align-items-center icons-nav">
                        <button
                          *ngIf="isWEPUser$ | async"
                          [routerLink]="'/workstation-expert-partner-lounge'"
                          class="btn btn-wep"
                        >
                          {{ 'Workstation Expert Partner Lounge' | translate }}
                        </button>
                        <button
                          *ngIf="isNa"
                          [routerLink]="'/promotions/1210'"
                          class="btn btn-ipr"
                        >
                          <img src="../../../../../assets/image/ipr-logo.png" />
                        </button>

                        <div class="ms-2">
                          <lib-switch-back-to-admin-button></lib-switch-back-to-admin-button>
                        </div>

                        <div class="mx-3">
                          <lib-notification-bell-dropdown></lib-notification-bell-dropdown>
                        </div>
                        <div class="mx-3">
                          <a [routerLink]="'/rewards-catalogue/cart'">
                            <fa-icon [icon]="['fas', 'shopping-cart']"></fa-icon>
                          </a>
                        </div>
                        <app-profile-menu class="mx-3"></app-profile-menu>
                        <app-main-menu [headerMenu]="headerMenu" class="mx-3"></app-main-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="me-3 me-xxl-4 d-none d-lg-flex justify-content-end align-items-center icons-nav absolute">
                <button
                  *ngIf="isWEPUser$ | async"
                  [routerLink]="'/workstation-expert-partner-lounge'"
                  class="btn btn-wep"
                >
                  {{ 'Workstation Expert Partner Lounge' | translate }}
                </button>
                <button
                  *ngIf="isNa"
                  [routerLink]="'/promotions/1210'"
                  class="btn btn-ipr"
                >
                  <img src="../../../../../assets/image/ipr-logo.png" />
                </button>
                <div class="ms-2">
                  <lib-switch-back-to-admin-button></lib-switch-back-to-admin-button>
                </div>

                <div class="mx-2 mx-xxl-3">
                  <lib-notification-bell-dropdown></lib-notification-bell-dropdown>
                </div>
                <div class="mx-2 mx-xxl-3">
                  <a [routerLink]="'/rewards-catalogue/cart'">
                    <fa-icon [icon]="['fas', 'shopping-cart']"></fa-icon>
                  </a>
                </div>
                <app-profile-menu class="ms-2 ms-xxl-3"></app-profile-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</app-modal-open-padding-wrapper>
