<lib-generic-list
  #genericList
  [dataProvider]="dataProvider"
  [totalCount]="totalCount"
  [columnsProvider]="columnsProvider"
  [serverPaging]="true"
  [noResultsMessageDisplayKey]="'No records found.' | translate"
  [defaultPagination]="false"
  [entriesDisplayKey]="
    totalCount
      ? (genericList.page - 1) * genericList.pageSize +
        1 +
        ' - ' +
        genericList.page * genericList.pageSize +
        ' ' +
        ('of' | translate) +
        ' ' +
        totalCount
      : ''
  "
  (stateChanged)="stateChanged.emit($event)"
>
  <ng-container *libGenericListCustomItemRenderer="'leapAward'; let row">
    <div class="d-flex align-items-center">
      <ng-container *ngIf="row | showPointsIcon">
        <img class="icon" src="assets/image/LUN2183_Points.svg" />
        <div class="ms-1">{{ row.points }}</div>
      </ng-container>
      <ng-container *ngIf="row | showCreditsIcon">
        <img class="ms-2 icon" src="assets/image/LUN2183_Credits.svg" />
        <div class="ms-1">{{ row.credits }}</div>
      </ng-container>
    </div>
  </ng-container>
</lib-generic-list>
