<lib-generic-list
  #genericList
  [dataProvider]="dataProvider"
  [totalCount]="totalCount"
  [columnsProvider]="columnsProvider"
  [noResultsMessageDisplayKey]="noResultsMessageDisplayKey"
  [itemsPerPageDisplayKey]="itemsPerPageDisplayKey"
  [entriesDisplayKey]="
    totalCount
      ? (genericList.page - 1) * genericList.pageSize +
        1 +
        ' - ' +
        genericList.page * genericList.pageSize +
        ' ' +
        ('of' | translate) +
        ' ' +
        totalCount
      : ''
  "
  [serverPaging]="true"
  [defaultPagination]="false"
  [tableStriped]="false"
  [actionsColumnLeft]="false"
  (stateChanged)="stateChanged.emit($event)"
>
  <ng-container *libGenericListCustomItemRenderer="'action'; let row">
    <ng-container *ngIf="row.status === 'Draft'">
      <button type="button" class="btn btn-sm btn-link" (click)="onEditClick(row.id)">{{ 'Edit' | translate }}</button>
      <button type="button" class="btn btn-sm btn-link" (click)="onDeleteClick(row.id)">
        {{ 'Delete' | translate }}
      </button>
    </ng-container>

    <button
      *ngIf="!row.consentFormAdded"
      type="button"
      class="btn btn-sm btn-link"
      (click)="onAddConsentFormClick(row.id)"
    >
      {{ 'Add consent form' | translate }}
    </button>

    <button *ngIf="!row.isCompleted" type="button" class="btn btn-sm btn-link" (click)="onEditClick(row.id)">
      {{ 'Incomplete' | translate }}
    </button>

    <button
      *ngIf="row.isCompleted && row.status === 'Draft'"
      type="button"
      class="btn btn-sm btn-link"
      (click)="onSubmitCompletedClick(row.id)"
    >
      {{ 'Submit completed' | translate }}
    </button>
  </ng-container>
</lib-generic-list>
