import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pcsd-toggle-button-group',
  templateUrl: './pcsd-toggle-button-group.component.html',
  styleUrls: ['./pcsd-toggle-button-group.component.scss'],
})
export class PcsdToggleButtonGroupComponent {
  @Input() showAll = false;
  @Input() isPcsd: boolean | null = false;

  @Output() isPcsdChange = new EventEmitter<boolean | null>();
}
