import { Component, Input } from '@angular/core';
import { PromotionSsgEmeaSidebarData } from 'src/app/core/model/promotion/promotion-ssg-emea-sidebar-data';

@Component({
  selector: 'app-ssg-emea-widget',
  templateUrl: './ssg-emea-widget.component.html',
  styleUrls: ['./ssg-emea-widget.component.scss'],
})
export class SsgEmeaWidgetComponent {
  private _ssgEmeaSidebarData: PromotionSsgEmeaSidebarData;

  @Input()
  get ssgEmeaSidebarData(): PromotionSsgEmeaSidebarData {
    return this._ssgEmeaSidebarData;
  }

  set ssgEmeaSidebarData(value: PromotionSsgEmeaSidebarData) {
    this._ssgEmeaSidebarData = value;
  }
}
