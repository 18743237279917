<div class="row" [formArrayName]="formArrayName">
  <div class="col-12">
    <label class="section-label">{{ label }}</label>
  </div>
  <div class="col-4" *ngFor="let itemChunk of items; let i = index">
    <div class="form-group" *ngFor="let item of itemChunk; let j = index">
      <div class="form-check" [formGroupName]="i * itemChunk.length + j">
        <input
          class="form-check-input"
          type="checkbox"
          [formControlName]="'checked'"
          [id]="formArrayName + '-item-' + (i * itemChunk.length + j)"
          (change)="onCheckboxChange(item, $event.target)"
        />
        <label class="form-check-label" [for]="formArrayName + '-item-' + (i * itemChunk.length + j)">{{
          item.name
        }}</label>
      </div>
    </div>
  </div>
</div>
