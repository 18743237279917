<div class="d-flex flex-column justify-content-center valencia-summary-wrapper" *ngIf="valenciaSummaryData">
  <div class="offcanvas-body d-flex flex-column">
    <h4 class="mb-0 text-uppercase title">{{ 'YOUR CURRENT POSITION!' | translate }}</h4>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-2 d-flex justify-content-between">
      <div class="label">{{ 'Position' | translate }}</div>
      <div>{{ valenciaSummaryData.position | number }}</div>
    </div>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-2 d-flex justify-content-between">
      <div class="label">{{ 'Number of invoices claimed' | translate }}</div>
      <div>{{ valenciaSummaryData.totalSalesClaimed | number }}</div>
    </div>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column" *ngIf="valenciaSummaryData.hasClaimedThreeBusinessGroups">
    <div class="mt-2 d-flex justify-content-between">
      <div class="label">{{ 'Invoices to be claimed to reach No 1' | translate }}</div>
      <div>{{ valenciaSummaryData.totalSalesNeeded | number }}</div>
    </div>
  </div>
  <div class="offcanvas-body d-flex flex-column my-3 eligible">
    <div class="py-2 d-flex" *ngIf="valenciaSummaryData.hasClaimedThreeBusinessGroups">
      <fa-icon [icon]="['far', 'check']" [size]="'xl'" class="text-success me-2"></fa-icon>
      <div>{{ 'You are eligible to compete!' | translate }}</div>
    </div>
    <div class="py-2 d-flex" *ngIf="!valenciaSummaryData.hasClaimedThreeBusinessGroups">
      <fa-icon [icon]="['far', 'xmark']" [size]="'xl'" class="text-danger me-2"></fa-icon>
      <div>{{ 'You must claim an invoice from all 3 business groups to be eligible!' | translate }}</div>
    </div>
  </div>
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-2" [innerHTML]="'Terms and Criteria' | translate"></div>
  </div>
</div>
