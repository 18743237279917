import { Component, EventEmitter, Output } from '@angular/core';

import { CustomListComponent } from '../custom-list/custom-list.component';

@Component({
  selector: 'app-custom-isg-submission-list',
  templateUrl: './custom-isg-submission-list.component.html',
  styleUrls: ['./custom-isg-submission-list.component.scss'],
})
export class CustomIsgSubmissionListComponent extends CustomListComponent {
  @Output() editButtonClick: EventEmitter<number> = new EventEmitter();
  @Output() deleteButtonClick: EventEmitter<number> = new EventEmitter();
  @Output() addConsentFormButtonClick: EventEmitter<number> = new EventEmitter();
  @Output() submitCompletedButtonClick: EventEmitter<number> = new EventEmitter();

  onEditClick(id: number): void {
    this.editButtonClick.emit(id);
  }

  onDeleteClick(id: number): void {
    this.deleteButtonClick.emit(id);
  }

  onAddConsentFormClick(id: number): void {
    this.addConsentFormButtonClick.emit(id);
  }

  onSubmitCompletedClick(id: number): void {
    this.submitCompletedButtonClick.emit(id);
  }
}
