<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ titleDisplayKey! | translate }}</h4>
</div>
<div class="modal-body">
  <div *ngIf="messages && messages.length === 1">{{ messages![0] | translate }}</div>
  <div *ngIf="messages && messages.length > 1">
    <div *ngFor="let messageItem of messages">{{ messageItem | translate }}</div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" type="submit" (click)="activeModal.close('cancel')">
    {{ 'Cancel' | translate }}
  </button>
  <button class="ms-2 btn btn-primary" type="submit" (click)="activeModal.close('ok')">
    {{ 'Ok' | translate }}
  </button>
</div>
