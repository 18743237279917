import { Injectable } from '@angular/core';
import { UrlTree, CanActivate, Router } from '@angular/router';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { filter, Observable, of, switchMap } from 'rxjs';

import { UserStoreService } from '../store/user-store.service';

function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

@Injectable({
  providedIn: 'root',
})
export class RedeemBlockedGuard implements CanActivate {
  constructor(private router: Router, private userStore: UserStoreService, private authStore: AuthStoreService) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authStore.isRegularUser$.pipe(
      filter(isNonNull),
      switchMap((isRegularUser: boolean) => {
        if (isRegularUser) {
          const redeemBlocked = !!this.authStore.userSettings?.claims.find((claim) => claim === 'user.redeemBlocked');

          if (redeemBlocked) {
            this.router.navigateByUrl('/home');
            return of(false);
          }
        }

        return of(true);
      })
    );
  }
}
