import { Component, Input } from '@angular/core';
import { MxLoggerService } from '@motivforce/mx-library-angular';
import { PromotionPremiumRewardsSidebarData } from 'src/app/core/model/promotion/promotion-premium-rewards-sidebar-data';

@Component({
  selector: 'app-intel-premium-rewards-widget',
  templateUrl: './intel-premium-rewards-widget.component.html',
  styleUrls: ['./intel-premium-rewards-widget.component.scss'],
})
export class IntelPremiumRewardsWidgetComponent {
  private _intelPremiumRewardsSidebarData: PromotionPremiumRewardsSidebarData;

  private _mode: 'Isg' | 'Idg' | '' | null = null;

  premiumRewardsData = {
    bonusCredits: 0,
    customerSubmissionCredits: 0,
    learnCredits: 0,
    marketingCredits: 0,
    registrationCredits: 0,
    roundTableCredits: 0,
    salesCredits: 0,
    totalCredits: 0,
    webinarCredits: 0,
  };

  today = new Date();

  constructor(private mxLogger: MxLoggerService) {}

  @Input()
  get mode(): 'Isg' | 'Idg' | '' | null {
    return this._mode;
  }

  set mode(value: 'Isg' | 'Idg' | '' | null) {
    this._mode = value;

    this.resetPremiumRewardsData();
  }

  @Input()
  get intelPremiumRewardsSidebarData(): any {
    return this._intelPremiumRewardsSidebarData;
  }

  set intelPremiumRewardsSidebarData(value: any) {
    this._intelPremiumRewardsSidebarData = value;

    this.resetPremiumRewardsData();
  }

  private resetPremiumRewardsData(): void {
    if (this.intelPremiumRewardsSidebarData) {
      Object.keys(this.premiumRewardsData).forEach((key) => {
        this.premiumRewardsData[key as keyof typeof this.premiumRewardsData] =
          this.intelPremiumRewardsSidebarData[key + (this.mode ?? '')];
      });
    }

    this.mxLogger.debug(
      'IntelPremiumRewardsWidgetComponent',
      'resetPremiumRewardsData() his.premiumRewardsData=',
      this.premiumRewardsData
    );
  }

  isInTheCurrentMonth(): boolean {
    switch (this.mode) {
      case 'Idg':
        return this.intelPremiumRewardsSidebarData?.participatedInPcsdThisMonth === true;
      case 'Isg':
        return this.intelPremiumRewardsSidebarData?.participatedInIsgThisMonth === true;
      default:
        return (
          this.intelPremiumRewardsSidebarData?.participatedInPcsdThisMonth === true ||
          this.intelPremiumRewardsSidebarData?.participatedInIsgThisMonth === true
        );
    }
  }
}
