<div class="d-flex align-items-center">
  <button class="p-0 btn" (click)="open(content)">
    <fa-icon class="d-inline" [icon]="['fass', 'bars']" [size]="'2x'"></fa-icon>
  </button>
</div>

<ng-template #content let-offcanvas>
  <div class="main-menu-wrapper">
    <div class="offcanvas-header">
      <button type="button" class="p-0 btn" aria-label="Close" (click)="offcanvas.dismiss('Cross click')">
        <fa-icon class="me-1" [icon]="['fass', 'xmark']" [size]="'2x'"></fa-icon>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="d-flex flex-column main-menu">
        <ng-container *ngFor="let item of headerMenu.items">
          <div class="h-100 d-flex align-items-center" routerLinkActive="active">
            <a [routerLink]="item.url" *ngIf="!item.url.includes('http')" (click)="offcanvas.dismiss()">
              <span class="nav-label" [innerHTML]="item.label"></span>
            </a>
            <a [href]="item.url" target="_blank" *ngIf="item.url.includes('http')">
              <span class="nav-label" [innerHTML]="item.label"></span>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
