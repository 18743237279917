import { Pipe, PipeTransform } from '@angular/core';
import { ProductPoint } from 'src/app/core/model/product/product-point';
import { UserSaleGroup } from 'src/app/core/model/user/user-sale-group';

@Pipe({
  name: 'showPointsIcon',
})
export class ShowPointsIconPipe implements PipeTransform {
  transform(value: UserSaleGroup | ProductPoint): boolean {
    if ((value as UserSaleGroup).sales) {
      return this.showUserSaleGroupIcon(value as UserSaleGroup);
    }

    return this.showProductPointIcon(value as ProductPoint);
  }

  private showUserSaleGroupIcon(value: UserSaleGroup): boolean {
    let show = false;

    value.sales?.forEach((sale) => {
      show = show || (sale.totalPoints !== null && sale.totalPoints > 0);
    });

    return show;
  }

  private showProductPointIcon(value: ProductPoint): boolean {
    return value.points !== null && value.points > 0;
  }
}
