import { Pipe, PipeTransform } from '@angular/core';
import { UserSaleGroup } from 'src/app/core/model/user/user-sale-group';

@Pipe({
  name: 'showCreditsIcon',
})
export class ShowCreditsIconPipe implements PipeTransform {
  transform(value: UserSaleGroup): boolean {
    let show = false;

    value.sales?.forEach((sale) => {
      show = show || (sale.credits !== null && sale.credits > 0);
    });

    return show;
  }
}
