<div class="footer-top-nav">
  <div
    [ngClass]="{
      'container-fluid': !loggedIn,
      container: loggedIn
    }"
  >
    <div class="row d-flex justify-content-between">
      <div class="col-sm-4 col-12 footer-menu-column" *ngIf="loggedIn">
        <h5>{{ 'Recent Tweets' | translate }}</h5>
        <a
          class="twitter-timeline"
          data-width="300"
          data-height="300"
          data-theme="dark"
          href="https://twitter.com/LenovoPartner?ref_src=twsrc%5Etfw"
          target="_blank"
          >{{ 'Tweets by LenovoPartner' | translate }}</a
        >
      </div>
      <div class="col-sm-4 col-12 footer-menu-column" *ngIf="loggedIn && footerMenuHelp">
        <h5>{{ 'Help & Support' | translate }}</h5>
        <div *ngFor="let item of footerMenuHelp.items">
          <a [routerLink]="item.url" *ngIf="!item.url.includes('http')">
            <span>{{ item.label }}</span>
          </a>
          <a [href]="item.url" target="_blank" *ngIf="item.url.includes('http')">
            <span>{{ item.label }}</span>
          </a>
        </div>
      </div>
      <div class="col-sm-4 col-12 footer-menu-column" *ngIf="loggedIn && footerMenuAbout">
        <h5>{{ 'About LEAP' | translate }}</h5>
        <div *ngFor="let item of footerMenuAbout.items">
          <a [routerLink]="item.url" *ngIf="!item.url.includes('http')">
            <span>{{ item.label }}</span>
          </a>
          <a [href]="item.url" target="_blank" *ngIf="item.url.includes('http')">
            <span>{{ item.label }}</span>
          </a>
        </div>
        <div class="social-media">
          <a href="https://www.x.com/lenovo" target="_blank"
            ><fa-icon [icon]="['fab', 'x-twitter']" size="xs"></fa-icon
          ></a>
          <a href="http://www.youtube.com/lenovo" target="_blank"
            ><fa-icon [icon]="['fab', 'youtube']" size="xs"></fa-icon
          ></a>
          <a href="http://www.linkedin.com/company/lenovo" target="_blank"
            ><fa-icon [icon]="['fab', 'linkedin']" size="xs"></fa-icon
          ></a>
        </div>
      </div>
      <div class="col-12 social-media text-end" *ngIf="!loggedIn">
        <a href="https://www.x.com/lenovo" target="_blank"
          ><fa-icon [icon]="['fab', 'x-twitter']" size="xs"></fa-icon
        ></a>
        <a href="http://www.youtube.com/lenovo" target="_blank"
          ><fa-icon [icon]="['fab', 'youtube']" size="xs"></fa-icon
        ></a>
        <a href="http://www.linkedin.com/company/lenovo" target="_blank"
          ><fa-icon [icon]="['fab', 'linkedin']" size="xs"></fa-icon
        ></a>
      </div>
    </div>
  </div>
</div>
