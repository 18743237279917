<footer class="footer">
  <div class="h-100 container">
    <div class="h-100 row">
      <div class="w-75 d-flex justify-content-start align-items-center">
        <div class="footer-item">
          <fa-icon class="me-1" [icon]="['fal', 'copyright']" size="xs"></fa-icon>
          <span>Lenovo {{ this.year }}</span>
        </div>
        <div class="footer-item">
          <a [href]="tncUrl" target="_blank">{{ 'Terms & Conditions' | translate }} </a>
        </div>
        <div class="footer-item">
          <a href="https://www3.lenovo.com/gb/en/privacy/" target="_blank">{{ 'Privacy' | translate }} </a>
        </div>
      </div>
    </div>
  </div>
</footer>
