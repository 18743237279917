<div class="btn-group" role="group" aria-label="IDG/ISG toggle">
  <button
    *ngIf="showAll"
    type="button"
    class="btn btn-outline-primary btn-grp"
    [ngClass]="{ active: isPcsd === null }"
    (click)="isPcsdChange.emit((isPcsd = null))"
  >
    {{ 'All' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-outline-primary btn-grp"
    [ngClass]="{ active: isPcsd === true }"
    (click)="isPcsdChange.emit((isPcsd = true))"
  >
    {{ 'IDG' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-outline-primary btn-grp"
    [ngClass]="{ active: isPcsd === false }"
    (click)="isPcsdChange.emit((isPcsd = false))"
  >
    {{ 'ISG' | translate }}
  </button>
</div>
