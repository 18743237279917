import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SkipSelf, APP_INITIALIZER, NgModule, Optional } from '@angular/core';
import {
  ContentStoreService,
  graphqlConfigFactory,
  MxLibraryAngularModule,
  CmsTranslateLoaderFactory,
  ProductCatalogueRestService,
} from '@motivforce/mx-library-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { environment } from 'src/environments/environment';

import { SharedModule } from '../shared/shared.module';
import { ProductCatalogueLenovoLeapRestService } from './api/rest/product-catalogue-lenovo-leap-rest.service';
import { AbstractTncComponent } from './components/abstract-tnc/abstract-tnc.component';
import { DefaultContentTemplateComponent } from './components/default-content-template/default-content-template.component';
import { DialogModule } from './dialog/dialog.module';
import { awsInitializerFactory } from './factory/aws-initializer.factory';
import { CustomHttpTokenInterceptor } from './interceptors/custom-http-token.interceptor';
import { LayoutModule } from './layout/layout.module';

@NgModule({
  declarations: [DefaultContentTemplateComponent, AbstractTncComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CmsTranslateLoaderFactory,
        deps: [Apollo, ContentStoreService],
      },
    }),
    MxLibraryAngularModule.forRoot({ environment }),
    LayoutModule,
    DialogModule,
    SharedModule,
  ],
  exports: [LayoutModule],
  providers: [
    HttpLink,
    {
      provide: APP_INITIALIZER,
      useFactory: awsInitializerFactory,
      deps: [],
      multi: true,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: graphqlConfigFactory,
      deps: [HttpLink],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpTokenInterceptor,
      multi: true,
    },
    {
      provide: ProductCatalogueRestService,
      useClass: ProductCatalogueLenovoLeapRestService,
    },
  ],
})
export class CoreModule {
  constructor(contentStore: ContentStoreService, @Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only once in the root module');
    }

    contentStore.registerTemplate('defaultTemplate', DefaultContentTemplateComponent);
  }
}
