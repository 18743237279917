<div class="d-flex flex-column justify-content-center wrapper" *ngIf="ultimateRaceSummaryData">
  <div class="offcanvas-body d-flex flex-column">
    <h4 class="mb-0 text-uppercase title">{{ 'Your company’s progress' | translate }}!</h4>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-2 d-flex justify-content-between">
      <div class="label">{{ 'Your company position' | translate }}</div>
      <div>{{ ultimateRaceSummaryData.position | number }}</div>
    </div>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-2 d-flex justify-content-between">
      <div class="label">{{ 'You company has claimed' | translate }}</div>
      <div>{{ ultimateRaceSummaryData.totalClaimed | number }}</div>
    </div>
  </div>
  <hr />
  <div class="offcanvas-body d-flex flex-column">
    <div class="mt-2 d-flex justify-content-between">
      <div class="label">{{ 'You have claimed' | translate }}</div>
      <div>{{ ultimateRaceSummaryData.userClaimed | number }}</div>
    </div>
  </div>
  <hr />

  <div class="offcanvas-body d-flex flex-column my-3 eligible">
    <div class="py-2 d-flex" *ngIf="ultimateRaceSummaryData.isEligible">
      <fa-icon [icon]="['far', 'check']" [size]="'xl'" class="text-success me-2"></fa-icon>
      <div>{{ 'You are Eligible' | translate }}</div>
    </div>
    <div class="py-2 d-flex" *ngIf="!ultimateRaceSummaryData.isEligible">
      <fa-icon [icon]="['far', 'xmark']" [size]="'xl'" class="text-danger me-2"></fa-icon>
      <div>{{ 'You are Ineligible' | translate }}</div>
    </div>
  </div>
</div>
