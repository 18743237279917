<lib-generic-list
  #genericList
  [dataProvider]="dataProvider"
  [totalCount]="totalCount"
  [columnsProvider]="columnsProvider"
  [noResultsMessageDisplayKey]="noResultsMessageDisplayKey"
  [itemsPerPageDisplayKey]="itemsPerPageDisplayKey"
  [entriesDisplayKey]="
    totalCount
      ? (genericList.page - 1) * genericList.pageSize +
        1 +
        ' - ' +
        genericList.page * genericList.pageSize +
        ' ' +
        ('of' | translate) +
        ' ' +
        totalCount
      : ''
  "
  [serverPaging]="true"
  [defaultPagination]="false"
  [tableStriped]="false"
  [actionsColumnLeft]="false"
  [rowHeight]="rowHeight"
  [selectable]="selectable"
  [allSelectable]="selectable"
  [expandableRow]="true"
  [defaultRowClick]="false"
  [itemSelectableFunction]="itemSelectableFunction"
  [itemHighlightableFunction]="itemHighlightableFunction"
  (stateChanged)="stateChanged.emit($event)"
>
  <ng-container *libGenericListCustomItemRenderer="'viewDetails'; let row">
    <div class="d-flex align-items-center">
      <button class="px-2 mt-1 mt-lg-0 btn btn-primary" (click)="genericList.openRowDetails(row)">
        {{ 'View details' | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container *libGenericListRowDetailsRenderer="''; let row">
    <div class="d-flex flex-row">
      <div class="d-flex flex-column line">
        <div class="mx-5 d-flex flex-column">
          <strong>{{ 'Invoice date' | translate }}</strong>
          <div>{{ row.invoiceDate | date: 'yyyy-MM-dd' }}</div>
        </div>
        <div class="mx-5 d-flex flex-column" *ngIf="row.naDetails?.distiName">
          <strong>{{ 'Distributor name' | translate }}</strong>
          <div>{{ row.naDetails.distiName }}</div>
        </div>
      </div>
      <div class="d-flex flex-column line">
        <div class="mx-5 d-flex flex-column" *ngIf="!row.naDetails && row.salesProductCategoryName">
          <strong>{{ 'Category' | translate }}</strong>
          <div>{{ row.salesProductCategoryName }}</div>
        </div>
        <div class="mx-5 d-flex flex-column">
          <strong>{{ 'Product' | translate }}</strong>
          <div>{{ row.description }} {{ row.modelNumber }}</div>
        </div>
      </div>
      <div class="d-flex flex-column line">
        <div class="mx-5 d-flex flex-column">
          <strong>{{ 'Claimed date' | translate }}</strong>
          <div>{{ row.claimDate | date: 'yyyy-MM-dd' }}</div>
        </div>
        <div class="mx-5 d-flex flex-column">
          <strong>{{ 'Quantity' | translate }}</strong>
          <div>{{ row.quantity }}</div>
        </div>
      </div>
      <div class="d-flex flex-column line">
        <div class="mx-3 d-flex flex-column">
          <strong>{{ 'Points' | translate }}</strong>
          <div *ngIf="row.totalPromotionPoints">
            {{ 'Base:' | translate }} {{ row.totalPoints - row.totalPromotionPoints }}
          </div>
          <div *ngIf="row.totalPromotionPoints">{{ 'Bonus: ' | translate }} {{ row.totalPromotionPoints }}</div>
          <div>{{ 'Total: ' | translate }} {{ row.totalPoints }}</div>
        </div>
      </div>
    </div>
  </ng-container>
</lib-generic-list>
